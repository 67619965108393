/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .cutline {
    margin: 0;
    width: 100%;
  }
}

.snFooterContainer {
  .heroPhotoElement {
    img {
      display: block;
      width: 50%;
      margin: 0 auto;
    }
  }
}
